<template>
  <CCard body-wrapper>
    <h1>Отчеты о расхождениях</h1>
    <CForm @submit.prevent="loadLines(1)" class="filters my-2 p-3">
      <CRow align-vertical="center">
        <CCol col="12" v-if="lastReportDate">
          <div class="filters__filter d-flex font-weight-normal align-items-center">
             Последняя выгрузка в {{ lastReportDate }}
             <loading-button
               class="mx-2"
               v-if="canCreateReport || newReportLoading"
               :loading="newReportLoading"
               text="Получить отчет"
               loadingText="Идет подготовка актуального отчета"
               @click="createNewReport"
             />
          </div>
        </CCol>
        <CCol col="auto">
          <div class="filters__filter">
            <div class="form-group">
              <label>Дата</label>
              <date-picker
                type="date"
                :value="computedDate"
                :clearable="false"
                :disabled-date="checkDate"
                @input="changeDate"
              />
            </div>
          </div>
        </CCol>
        <CCol xs="12" xl="4">
          <div
            class="filters__filter"
            :class="{ 'filters__filter--active': filters.article }"
          >
            <CInput
              label="Артикул"
              :value.sync="filters.article"
            />
          </div>
        </CCol>
        <CCol xs="12" xl="4">
          <div class="filters__filter" :class="{ 'filters__filter--active': filters.onlyDiff}">
            <CSelect
              :options="[{value: false, label: 'Нет'}, {value: true, label: 'Да'}]"
              label="Только с расхождениями"
              :value.sync="filters.onlyDiff"
            />
          </div>
        </CCol>
        <CCol
          xs="12"
          xl="4"
        >
          <div class="filters__filter">
            <loading-button :loading="loading" text="Поиск" />
            <CButton
              class="ml-2"
              color="secondary"
              style="width: 90px;"
              @click="clearFilters"
            >
              Очистить
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
    <CCard class="mt-2">
      <CDataTable
        :loading="loading"
        :items="lines"
        :fields="tableFields"
        hover
      >
        <template #loading>
          <CElementCover
            :opacity="0.8"
          >
            <CSpinner
              class="custom-spinner"
              color="info"
            />
          </CElementCover>
        </template>
        <template #no-items-view>
          <div class="text-center">
            Не удалось загрузить данные
          </div>
        </template>
        <template #article="{item}">
          <td class="py-2 font-weight-bold">
            {{ item.article }}
          </td>
        </template>
        <template #store="{item}">
          <td class="py-2">
            <div>
              {{ item.store && item.store.fullName }}
              <span class="font-weight-bold">{{ selfQuantityText(item) }}</span>
            </div>
          </td>
        </template>
        <template #externalStore="{item}">
          <td class="py-2">
            <div>
              {{ item.externalStore && item.externalStore.name }}
              <span class="font-weight-bold">{{ externalQuantityText(item) }}</span>
            </div>
          </td>
        </template>
        <template #quantityDiff="{item}">
          <td class="py-2" :class="{'py-2': true, 'text-success': item.quantityDiff > 0, 'text-danger': item.quantityDiff < 0}">
            {{ item.quantityDiff }} шт.
          </td>
        </template>
      </CDataTable>
      <CPagination
       :activePage="page"
       :pages="totalPages"
       v-on:update:activePage="changePage"
      />
    </CCard>
  </CCard>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import LoadingButton from '@/components/loading-button';
import {get} from 'lodash';

export default {
  name: 'ReportIndex',
  components: {
    DatePicker,
    LoadingButton
  },
  data() {
    return {
      lines: [],
      page: 1,
      newReportLoading: false,
      totalPages: 1,
      loading: true,
      availableDates: [],
      availableReports: {},
      filters: {
        reportRequest: null,
        onlyDiff: false,
        article: ''
      }
    }
  },
  mounted() {
    this.onMounted();
  },
  computed: {
    computedDate() {
      const {reportRequest} = this.filters;
      if (!reportRequest) {
        return null;
      }

      return moment(reportRequest.createdAt).toDate();
    },
    lastReportDate() {
      if (!this.lastReport) {
        return null;
      }
      return moment(this.lastReport.createdAt).format('HH:mm')
    },
    lastReport() {
      const {availableReports, availableDates} = this;
      if (!availableDates.length) {
        return null;
      }
      const lastReports = availableReports[availableDates.at(0).format('x')];
      return lastReports.at(0);
    },
    canCreateReport() {
      if (!this.lastReport) {
        return false;
      }
      return moment(this.lastReport.createdAt).isBefore(moment().subtract(20, 'minutes'))
    },
    firstLoading() {
      const {loading, lines} = this;
      return !lines.length && loading;
    },
    reportFilters() {
      return {
        onlyDiff: this.filters.onlyDiff,
        article: this.filters.article,
        reportRequestId: get(this.filters.reportRequest, 'id')
      }
    },
    tableFields() {
      return [
        {key: 'article', label: 'Артикул'},
        {key: 'quantityDiff', label: 'Отличие остатка'},
        {key: 'externalStore', label: 'Склад МС'},
        {key: 'store', label: 'Склад ФФ'}
      ]
    }
  },
  methods: {
    selfQuantityText(item) {
      if (item.quantitySelf == null) {
        return ''
      }

      return `(${item.quantitySelf} шт.)`
    },
    externalQuantityText(item) {
      if (item.quantityExternal == null) {
        return ''
      }

      return `(${item.quantityExternal} шт.)`
    },
    async onMounted() {
      await this.loadDates();
      if (this.canCreateReport) {
        this.createNewReport();
      }
    },
    changeDate(dt) {
      const dtString = moment(dt).format('x');
      const reports = this.availableReports[dtString];
      if (!reports) {
        return;
      }

      this.filters.reportRequest = reports[reports.length - 1];
    },
    changePage(newPage) {
      this.loadLines(newPage);
    },
    clearFilters() {
      this.filters = {
        withDiff: false,
        article: '',
        reportRequest: null,
      }
    },
    checkDate(dt) {
      return !this.availableDates.find(el => moment(dt).isSame(el, 'day'));
    },
    async createNewReport() {
      try {
        this.newReportLoading = true;
        await this.$axios.post('/api/reports');
        await this.loadDates()
      } catch(e) {
        console.error(e);
      } finally {
        this.newReportLoading = false;
      }
    },
    async loadLines(page = 1) {
      try {
        this.loading = true;
        this.lines = [];
        const params = {...this.reportFilters, page};
        const {data} = await this.$axios.get('api/reports', {params});
        this.lines = data.list;
        this.totalPages = data.totalPages;
        this.page = page;
      } catch(e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async loadDates() {
      try {
        const {data} = await this.$axios.get('/api/reports/available_reports');
        this.availableReports = data;
        this.availableDates = Object.keys(data).map((el) => moment(Number(el)));
        console.log(this.availableDates, this.availableReports);
        if (this.lastReport) {
          this.filters.reportRequest = this.lastReport
        }
        this.loadLines();
      } catch(e) {
        console.error(e);
      }
    }
  }
}
</script>
